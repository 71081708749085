<!--新闻动态-->
<template>
<div>
        <div v-if="newslist.length > 0">
<div class="content" v-for="(item,index) in newslist" :key="index">
                <img :src="item.introduceImg" alt="">
                <p class="contenttitle" style="margin-top: 20px">
                      <!-- {{$t('language.InvestmentFranchiseand')}} -->
                      {{item.title}}
                    <!-- 2021年中国国际酒店投资加盟与特许经营展览会盛大开幕！ -->
                </p>
                <p class="contenttext">
                    {{item.introduce}}
                      <!-- {{$t('language.ConventionandExhibitionCo')}} -->
                    <!-- 由烟台八角湾国际会展有限公司主办的2021中国国际酒店投资加盟与特许经营展览会于26日上午9:00在八角湾国际会展中心2号馆拉开帷幕。本次展览会集结了众多国内外头部酒店管理集团以及酒店用品、智慧酒店、酒店设计工程优质企业...... -->
                    </p><br>
                <input type="button" :value="$t('language.viewdetails')" @click="lookparticulars(item.id)">
                <p class="contentright" style="width:130px">
                    <span style="font-size: 60px;font-family: Microsoft YaHei;font-weight: bold;color: #353535;line-height: 30px">{{item.date}}</span><br>
                    <span style="font-size: 18px;font-family: Microsoft YaHei;font-weight: bold;color: #353535;line-height: 30px;">{{item.year}}</span>
                </p>
            </div>
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
           :current-page="pageNum"
           :page-size="pageSize"
            layout="total,prev, pager, next, jumper"
            :total="total" class="pagination">
       </el-pagination>
        </div>
            <div v-if="newslist.length == 0" style="width:100%;display: flex;justify-content: center;">
                    <img style="width:400px;height:300px" src="../../assets/image/queshengye.png" alt="" />
                  </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            newslist:[],
            total:null,
            pageNum:1,
            pageSize:5,
        }
    },
    created(){
        this.getlist()
    },
   methods: {
       getlist(){
           let data ={
            pageNum:this.pageNum,
            pageSize:this.pageSize,
            newsType:'1'
        }
        this.$api.newsandtrends(data,res=>{
            if(res.data.code){
                let newslist = res.data.rows.map((item)=>{
                    item.date = item.releaseTime.substring(8,11)
                    item.year = item.releaseTime.substring(0,7)
                    item.newsContent = `${item.newsContent.substring(0,150)}...`
                    return item
                })
                 console.log(res.data);
                 this.newslist = newslist
                 this.total=res.data.total;
            }
        })
       },
    lookparticulars(id){
        this.$router.push({path:'lookparticulars',query:{id:id,path:'新闻动态'}})
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getlist()
    },

    handleCurrentChange(pageNum) {
      this.pageNum= pageNum;
      this.getlist()
    }
  }
};
</script>

<style scoped>
    .content{
        min-height: 254px;
        padding: 20px;
        background-color: #FAFAFA;
        margin-bottom: 30px;
        margin-top: 10px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
    }
    .content img{
        width: 348px;
        height: 246px;
        float: left;
        margin-right: 30px
    }
    .content p{
        width: 800px;
    }
    .contenttitle{
        height: 62px;
        font-size: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
    }
    .contenttext{
        color: #595959 !important;
        margin-top: 10px;
        display: inline-block;
        height: 88px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    element.style{
        color: #595959 !important;
    }
    .contenttext span{
        color: #595959 !important;
    }
    /* .contenttext{
        margin-top: 10px;
        height: 88px;
        display: inline-block;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
    } */
    input{
        width: 163px;
        height: 48px;
        background: #197CC3;
        border-radius: 5px;
        color: #fff;
        border: 0;
    }
    .contentright{
        float: right;
        text-align: center;
        margin-top: -100px
    }
    ::v-deep .el-pager li{
        font-size: 20px;
    }
   ::v-deep .el-pagination__jump{
       font-size: 20px !important;
   }
   ::v-deep .el-pagination__total{
        font-size: 20px !important;
   }
   ::v-deep .el-input__inner{
        font-size: 20px;
   }
   ::v-deep .el-icon-arrow-left:before{
       font-size: 20px;
   }
   ::v-deep .el-icon-arrow-right:before{
       font-size: 20px;
   }
</style>
