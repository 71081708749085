var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TradeShowTrends"},[_c('div',{staticClass:"navs"},[_c('Carousel',{attrs:{"show":"1"}}),_c('div',{staticClass:"TradeShowTrendsCenter"},[_c('div',{staticClass:"navcon"},[_c('p',{staticClass:"navconleft"},[_c('span',{style:({
              background: _vm.show == 'NewsInformation' ? '#197CC3' : '#fff',
              color: _vm.show == 'NewsInformation' ? '#fff' : '#000',
              zIndex: _vm.show == 'NewsInformation' ? '5' : '',
            }),on:{"click":function($event){return _vm.gopage('NewsInformation', $event)}}},[_vm._v(" "+_vm._s(_vm.$t("language.NewInformation"))+" "),_c('b',{staticStyle:{"font-weight":"normal"}},[_vm._v("|")])]),_c('span',{style:({
              background: _vm.show == 'wonderful' ? '#197CC3' : '#fff',
              color: _vm.show == 'wonderful' ? '#fff' : '#000',
              zIndex: _vm.show == 'wonderful' ? '5' : '',
            }),on:{"click":function($event){return _vm.gopage('wonderful', $event)}}},[_vm._v(" "+_vm._s(_vm.$t("language.WonderfulMoment"))+" "),_c('b',{staticStyle:{"font-weight":"normal"}},[_vm._v("|")])]),_c('span',{style:({
              background: _vm.show == 'ExhibitionScheduling' ? '#197CC3' : '#fff',
              color: _vm.show == 'ExhibitionScheduling' ? '#fff' : '#000',
              zIndex: _vm.show == 'ExhibitionScheduling' ? '5' : '',
            }),on:{"click":function($event){return _vm.gopage('ExhibitionScheduling', $event)}}},[_vm._v(" "+_vm._s(_vm.$t("language.ExhibitionScheduling"))+" "),_c('b',{staticStyle:{"font-weight":"normal"}},[_vm._v("|")])]),_c('span',{style:({
              background: _vm.show == 'NoticeNotice' ? '#197CC3' : '#fff',
              color: _vm.show == 'NoticeNotice' ? '#fff' : '#000',
              zIndex: _vm.show == 'NoticeNotice' ? '5' : '',
            }),on:{"click":function($event){return _vm.gopage('NoticeNotice', $event)}}},[_vm._v(" "+_vm._s(_vm.$t("language.NoticeNotice"))+" ")])]),_c('p',{staticClass:"navconright"},[_c('van-icon',{attrs:{"color":"#999999","name":"wap-home","size":"20"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("language.current"))+" "),_vm._v(" : "+_vm._s(_vm.$t("language.HOMEpage"))+" "),_vm._v(" > "),_c('span',{staticClass:"spans",domProps:{"innerHTML":_vm._s(_vm.$t('language.ExhibitionTrends'))}}),_vm._v(" > "+_vm._s(_vm.$t(("language." + _vm.show)))+" ")])],1)]),(_vm.show == 'NewsInformation')?_c('div',{staticClass:"content"},[_c('NewsInformation')],1):(_vm.show == 'wonderful')?_c('div',{staticClass:"content"},[_c('wonderful')],1):(_vm.show == 'ExhibitionScheduling')?_c('div',{staticClass:"content"},[_c('ExhibitionScheduling')],1):(_vm.show == 'ExhibitionProcess')?_c('div',{staticClass:"content"},[_c('ExhibitionProcess')],1):(_vm.show == 'NoticeNotice')?_c('div',{staticClass:"content"},[_c('NoticeNotice')],1):_vm._e()]),_c('Bottom')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }