<!-- 展会排期 -->
<script>

</script>

<template>
          <div class="content">
              <div class="contenttop">
                  <input type="button" v-if="lastYearsoff" :value="this.lastYears"   :class="{yearClickEffect : clickYear == lastYears}" @click="yearClick(lastYears)"
                   >
                  <input type="button" :value="this.nowYears"     :class="{yearClickEffect : clickYear == nowYears}" @click="yearClick(nowYears)"
                   >
              </div>
              <div class="contenttime">
                  <img src="../../assets/image/组36.png" alt="">
                  <div class="monthlist">
                  <!-- <img :src="item.flag==true?item.imgs:imgUrls" v-for="(item,index) in imgUrl" :key="index" alt="" class="minimg" @click="getCurImg(item)"> -->
                   <img v-if="yuefenoff" :src="imgUrl[0].flag==true?imgUrl[0].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[0])">
                   <img v-if="yuefenoff" :src="imgUrl[1].flag==true?imgUrl[1].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[1])">
                   <img v-if="yuefenoff" :src="imgUrl[2].flag==true?imgUrl[2].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[2])">
                   <img v-if="yuefenoff" :src="imgUrl[3].flag==true?imgUrl[3].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[3])">
                   <img :src="imgUrl[4].flag==true?imgUrl[4].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[4])">
                   <img :src="imgUrl[5].flag==true?imgUrl[5].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[5])">
                   <img :src="imgUrl[6].flag==true?imgUrl[6].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[6])">
                   <img :src="imgUrl[7].flag==true?imgUrl[7].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[7])">
                   <img :src="imgUrl[8].flag==true?imgUrl[8].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[8])">
                   <img :src="imgUrl[9].flag==true?imgUrl[9].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[9])">
                   <img :src="imgUrl[10].flag==true?imgUrl[10].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[10])">
                   <img :src="imgUrl[11].flag==true?imgUrl[11].imgs:imgUrls" alt="" class="minimg" @click="getCurImg(imgUrl[11])">
                   </div>
                  <img src="../../assets/image/组36拷贝.png" alt="">
                  <div class="line">
                  </div>
                  <div class="timetext">
                      <span v-if="yuefenoff">
                           {{ $t("language.January") }}
                      </span>
                      <span v-if="yuefenoff">
                           {{ $t("language.February") }}
                      </span>
                      <span v-if="yuefenoff">
                           {{ $t("language.March") }}
                      </span>
                      <span v-if="yuefenoff">
                           {{ $t("language.April") }}
                      </span>
                      <span>
                           {{ $t("language.May") }}
                      </span>
                      <span>
                           {{ $t("language.June") }}
                      </span>
                      <span>
                           {{ $t("language.July") }}
                      </span>
                      <span>
                           {{ $t("language.August") }}
                      </span>
                      <span>
                           {{ $t("language.September") }}
                      </span>
                      <span>
                           {{ $t("language.October") }}
                      </span>
                      <span>
                           {{ $t("language.November") }}
                      </span>
                      <span>
                           {{ $t("language.December") }}
                      </span>
                  </div>
                  <div class="timetextyear">
                      <p>
                        {{clickYear}}年
                      </p>
                  </div>
              </div>
              <div class="contentbottom">
                <el-row v-if="contentList.length > 0" type="flex"  :gutter="80" style=" flex-wrap: wrap">
                  <el-col :span="8" v-for="(item,index) in contentList" :key="index" >
                  <div class="bottombox1" >
                      <div class="bottombox3">
                        <div class="introduceImg" :style="{backgroundImage: 'url(' +item.introduceImg + ')'}">
                        </div>
                          <p class="bottomTitle">
                              {{ $t(item.title) }}
                          </p>
                          <p class="bottomTime">
                              {{item.startTime}}-{{item.endTime}}
                          </p>
                        </div>
                        <div class="bottombox2" style="padding-left:30px;padding-top:60px;box-sizing:border-box">
                          <p style="width: 320px;height: 20px;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #fff;line-height: 20px;margin-bottom: 20px">
                              {{ $t(item.title) }}
                          </p>
                          <p style="height: 11px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #fff;line-height: 14px;margin-bottom: 40px">{{item.startTime}}-{{item.endTime}}</p>
                          <p @click="GoUrl(item.url)" style="cursor: pointer;width: 339px;height: 50px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #93c9ee;line-height: 25px;margin-bottom:40px;word-wrap:break-word;word-break:normal;">
                              {{item.url}}</p>
                          <p style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #93c9ee;line-height: 30px;">
                              地址： {{ $t(item.address) }}
                          </p>
                          <!-- <p style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #93c9ee;line-height: 30px;">
                            主办方： {{item.sponsor}}
                          </p> -->
                          <p v-if="item.linkman" style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #93c9ee;line-height: 30px;">
                            联系人： {{item.linkman}} {{item.phone}}
                          </p>
                      </div>
                  </div>
                  </el-col>
                </el-row>
                  <div v-if="contentList.length == 0" style="width:100%;display: flex;justify-content: center;">
                    <img style="width:400px" src="../../assets/image/queshengye.png" alt="" />
                  </div>
                  </div>
          </div>
</template>

<script>
import {exhibition} from "../../api/api";

export default {
  data(){
      return{
        total:0,
        pageNum:1,
        pageSize:6,
        yuefenoff:true,
        lastYearsoff:true,
        nowYears:null,
        lastYears:null,
        clickYear:null,
        clickMonth:null,
        //数据列表
        contentList:[],
        imgUrls:require('../../assets/image/矩形35拷贝 7.png'),
        imgUrl:[
            {id:1,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:2,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:3,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:4,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:5,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:6,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:7,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:8,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:9,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:10,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:11,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:12,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
        ],
         height:"calc(100% - 70px)",
         startTime:"",endTime:"",sponsor:"",title:"",introduceImg:"",url:"",address:"",phone:""
      };
      },
    created(){
      this.getCurrentYear();
      this.getlist();
    },
   methods: {
     yearClick(name){
       this.resetPagination();
       this.clickYear = name;
       this.getlist();
     },
    //获取当前年和去年日期
     getCurrentYear() {
       let date = new Date();
       this.nowYears = date.getFullYear();
       this.clickYear = date.getFullYear();
       this.clickMonth = this.zeroize(date.getMonth()+1);
       let item =  {id: date.getMonth()+1,imgs:require('../../assets/image/矩形35拷贝 8.png'),
         flag:true,
       }
       this.getCurImg(item);
       this.lastYears = this.nowYears-1
       if (this.lastYears<2022) {
         this.lastYearsoff = false
       }
       if(this.nowYears == 2022){
         this.yuefenoff = false  
       }
     },
    //加载展会列表
      getlist(){
       let data ={
         pageNum:this.pageNum,
         pageSize:this.pageSize,
         yearMonth:this.clickYear+"-"+this.clickMonth
       }
       this.$api.exhibition(data,res=>{
         if(res.data.code === 200){
           console.log(res.data);
           this.contentList = res.data.data
         }
       })
     },
     GoUrl(event){
         window.open(event,"_blank");
    },
     //根据条数显示
     handleSizeChange(pageSize) {
       this.pageSize = pageSize;
       this.getlist()
     },
     //根据页数显示
     handleCurrentChange(pageNum) {
       this.pageNum= pageNum;
       this.getlist()
     },
      inpwcolor: function(e){
         e.target.style.backgroundColor = "#E4E4E4"
          e.target.style.color="#000"
      },
     //大于10补零方法
     zeroize(obj) {
       if (obj < 10) {
         return '0' + obj
       } else {
         return obj
       }
     },
     resetPagination(){
       this.pageNum = 1;
       this.pageSize = 6;
     },
     //拿到点击状态
    getCurImg: function(item){
      this.imgUrl.map((items)=>{
          if(item.id == items.id){
            this.resetPagination();
            this.clickMonth = this.zeroize(item.id);
            items.flag= false
            this.getlist()
          }else{
              items.flag=true
          }
          return items
      })
    },
  }
};
</script>

<style scoped>
  .yearClickEffect{
    background-color : #197CC3;
    color : #fff;
  }
  .introduceImg{
    width: 100%;
    height: 80%;
    background-size:100%,100%;
    background-repeat:no-repeat;
  }
  .content{
        min-height: 254px;
        padding: 20px;
        background-color: #FAFAFA;
        margin-bottom: 30px;
        margin-top: 10px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
    }
    .contenttop{
        margin-left: 1000px;
        margin-top: 30px
    }
    .contenttop>input{
        width: 142px;
        height: 49px;
        margin-left: 20px;
        border: 0
    }
    .contenttime{
        margin-top: 50px;
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        position:relative;
    }
    .monthlist{
      width: 1093px;
      display:flex;
      justify-content: space-around;
    }
    .contenttime>img{
        z-index: 1;
    }
    .minimg{
        width: 20px;
        height: 20px;
        z-index: 200
    }
    .line{
        width: 1170px;
        height: 1px;
        border: 1px solid #CCCCCC;
        background-color:#CCCCCC;
        position: absolute;
        left:92px;
        top:28px;
    }
    .timetext{
         width: 1100px;
         height: 20px;
         position: absolute;
         display:flex;
         justify-content: space-around;
         left:130px;
         top:-10px;
    }
    .timetext>span{
        width: 50px;
        text-align: center;
    }
    .timetextyear{
        position: absolute;
        z-index: 100;
    }
    .timetextyear>p{
        position: relative;
        top: 30px;
        right: 500px;
    }
    .contentbottom{
        margin-top: 55px;
     /*   display: flex;
        justify-content: space-between ;
        flex-wrap: wrap;*/
        margin-bottom: 40px;
    }
    .bottombox1{
        width: 400px;
        height: 380px;
        background: #FFFFFF;
        margin-top: 50px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.1);
        position: relative;
    }
    .bottombox1>.bottombox3{
        width: 100%;
        height: 100%;
        display: block;
        backface-visibility: hidden;
        transition: all 1s;
    }
    .bottombox2{
        width: 100%;
        height: 100%;
        display: block;
        background-color: #409EFF;
        transition: all 1s;
        transform:rotateY(180deg);
        backface-visibility: hidden;
        position: absolute;
        left:0;
        top: 0;
    }
    .bottombox1:hover .bottombox2{
        transform:rotateY(360deg);
    }
    .bottombox1:hover .bottombox3{
        transform:rotateY(180deg);
    }
    .bottomTitle{
        width: 350px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        margin-left: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

    }
    .bottomTime{
        width: 148px;
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-left: 30px;
    }
    .SerialNumber{
        width: 700px;
        margin-left: 650px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
    }
    .SerialNumber>p{
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        color: #333333;
        border: 1px solid #CCCCCC;
        text-align: center;
        line-height: 40px;
        margin-left: 20px;
    }
   .el-pagination /deep/{
     margin-left: 20px;

   }
   ::v-deep .el-pager li{
     font-size: 20px;
   }
   ::v-deep .el-pagination__jump{
     font-size: 20px !important;
   }
   ::v-deep .el-pagination__total{
     font-size: 20px !important;
   }
   ::v-deep .el-input__inner{
     font-size: 20px;
   }
   ::v-deep .el-icon-arrow-left:before{
     font-size: 20px;
   }
   ::v-deep .el-icon-arrow-right:before{
     font-size: 20px;
   }
</style>
