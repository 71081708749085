<!--展会动态-->
<template>
  <div class="TradeShowTrends">
    <div class="navs">
      <Carousel show="1" />
      <div class="TradeShowTrendsCenter">
        <div class="navcon">
          <p class="navconleft">
            <!-- 新闻动态 -->
            <span
              :style="{
                background: show == 'NewsInformation' ? '#197CC3' : '#fff',
                color: show == 'NewsInformation' ? '#fff' : '#000',
                zIndex: show == 'NewsInformation' ? '5' : '',
              }"
              @click="gopage('NewsInformation', $event)"
            >
              {{ $t("language.NewInformation") }}
              <b style="font-weight: normal">|</b>
            </span>
            <!-- 精彩瞬间 -->
            <span
              :style="{
                background: show == 'wonderful' ? '#197CC3' : '#fff',
                color: show == 'wonderful' ? '#fff' : '#000',
                zIndex: show == 'wonderful' ? '5' : '',
              }"
              @click="gopage('wonderful', $event)"
            >
              {{ $t("language.WonderfulMoment") }}
              <b style="font-weight: normal">|</b>
            </span>
            <!-- 展会排期 -->
            <span
              :style="{
                background: show == 'ExhibitionScheduling' ? '#197CC3' : '#fff',
                color: show == 'ExhibitionScheduling' ? '#fff' : '#000',
                zIndex: show == 'ExhibitionScheduling' ? '5' : '',
              }"
              @click="gopage('ExhibitionScheduling', $event)"
            >
              {{ $t("language.ExhibitionScheduling") }}
              <b style="font-weight: normal">|</b>
            </span>
            <!-- 通知公告 -->
            <span
              :style="{
                background: show == 'NoticeNotice' ? '#197CC3' : '#fff',
                color: show == 'NoticeNotice' ? '#fff' : '#000',
                zIndex: show == 'NoticeNotice' ? '5' : '',
              }"
              @click="gopage('NoticeNotice', $event)"
            >
              {{ $t("language.NoticeNotice") }}
            </span>
            <!-- 办展流程 -->
            <!-- <span
              :style="{
                background: show == 'ExhibitionProcess' ? '#197CC3' : '#fff',
                color: show == 'ExhibitionProcess' ? '#fff' : '#000',
                zIndex: show == 'ExhibitionProcess' ? '5' : '',
              }"
              @click="gopage('ExhibitionProcess', $event)"
            >
              {{ $t("language.ExhibitionProcess") }}
            </span> -->
          </p>
          <p class="navconright">
            <van-icon color="#999999" name="wap-home" size="20" />
            <span>
              {{ $t("language.current") }}
              <!-- 当前位置 -->
              :
              {{ $t("language.HOMEpage") }}
              <!-- 首页 -->
              >
              <!-- {{$t('language.ExhibitionTrends')}} -->
              <span
                class="spans"
                v-html="$t('language.ExhibitionTrends')"
              ></span>
              <!-- 展会动态 -->
              >
              {{ $t(`language.${show}`) }}
            </span>
          </p>
        </div>
        <div class="content" v-if="show == 'NewsInformation'">
          <NewsInformation />
        </div>
        <div class="content" v-else-if="show == 'wonderful'">
          <wonderful />
        </div>
        <div class="content" v-else-if="show == 'ExhibitionScheduling'">
          <ExhibitionScheduling />
        </div>
        <div class="content" v-else-if="show == 'ExhibitionProcess'">
          <ExhibitionProcess />
        </div>
        <div class="content" v-else-if="show == 'NoticeNotice'">
          <NoticeNotice />
        </div>
      </div>
      <Bottom />
    </div>
  </div>
</template>

<script>
import NewsInformation from "../../components/pcTradeShowTrends/NewsInformation.vue";
import wonderful from "../../components/pcTradeShowTrends/wonderful.vue";
import ExhibitionScheduling from "../../components/pcTradeShowTrends/ExhibitionScheduling.vue";
import ExhibitionProcess from "../../components/pcTradeShowTrends/ExhibitionProcess.vue";
import NoticeNotice from "../../components/pcTradeShowTrends/NoticeNotice.vue";
import Carousel from "../../components/pc-carousel.vue";
import Bottom from "../../components/bottom.vue";
export default {
  components: {
    Carousel,
    Bottom,
    NewsInformation,
    wonderful,
    ExhibitionScheduling,
    ExhibitionProcess,
    NoticeNotice
  },
  data() {
    return {
      show: "",
    };
  },
  created() {
    this.showcomponent();
  },
  mounted() {
    let traffic = document.getElementsByClassName("TradeShowTrends")[0];
    this.$nextTick(() => {
      setTimeout(() => {
        let targetbox = document.getElementsByClassName("navcon")[0];
        traffic.scrollTop = targetbox.offsetTop;
      }, 500);
    });
  },
  methods: {
    showcomponent() {
      if (this.$route.query.path == "新闻动态") {
        this.show = "NewsInformation";
      } else if (this.$route.query.path == "精彩瞬间") {
        this.show = "wonderful";
      } else if (this.$route.query.path == "展会排期") {
        this.show = "ExhibitionScheduling";
      } else if (this.$route.query.path == "通知公告") {
        this.show = "NoticeNotice";
      }/* else if (this.$route.query.path == "办展流程") {
        this.show = "ExhibitionProcess";
      }*/ else if (this.$route.query.path == "/") {
        this.show = "NewsInformation";
      } else if (this.$route.query.path == "") {
        this.show = "NewsInformation";
      }
    },
    gopage(val, e) {
      if (val == "NewsInformation") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `新闻动态` },
        });
      } else if (val == "wonderful") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `精彩瞬间` },
        });
      } else if (val == "ExhibitionScheduling") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `展会排期` },
        });
      }else if (val == "NoticeNotice") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `通知公告` },
        });
      }  /*else if (val == "ExhibitionProcess") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `办展流程` },
        });
      }*/
      this.show = val;
      e.target.style.backgroundColor = "#197CC3";
      e.target.style.color = "#fff";
    },
  },
};
</script>

<style scoped>
.TradeShowTrends::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.navs {
  width: 100%;
  height: 100%;
}
.spans >>> span {
  font-size: 15px !important;
}
a {
  color: black;
}
.TradeShowTrends {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.TradeShowTrendsCenter {
  width: 1400px;
  margin: 0 auto;
  margin-bottom: 200px;
}
.navcon {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}
.navconleft span {
  display: inline-block;
  line-height: 70px;
  text-align: center;
  padding: 0px 20px;
}
.navconleft :hover {
  background-color: #1a7cc3 !important;
  color: white !important;
  cursor: pointer;
  z-index: 5 !important;
}
.navconleft :hover b {
  display: none !important;
}
.navconleft span {
  position: relative;
}
.navconleft b {
  position: absolute;
  z-index: 2 !important;
  right: -3px;
}
.navconright {
  line-height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navconright > span {
  display: inline-block;
}
/* .content{
        min-height: 254px;
        padding: 20px;
        background-color: #FAFAFA;
        margin-bottom: 30px;
        margin-top: 10px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
    } */
</style>
